import { createRouter, createWebHistory } from 'vue-router'

// Import Layouts
import FrontendLaout from '@/layouts/Frontend.vue'
import BackendLayout from '@/layouts/Backend.vue'

// Import Views
// Frontend
import HomeView from '@/views/frontend/HomeView.vue'
import AboutView from '@/views/frontend/AboutView.vue'
import PortfolioView from '@/views/frontend/PortfolioView.vue'
import ContactView from '@/views/frontend/ContactView.vue'
import LoginView from '@/views/frontend/LoginView.vue'
// import RegisterView from '@/views/frontend/RegisterView.vue'
import ServiceView from '@/views/frontend/ServiceView.vue'
import ForgotPasswordView from '@/views/frontend/ForgotPasswordView.vue'
import NotFound404View from '@/views/frontend/NotFound404View.vue'

// Backend
import DashbaordView from '@/views/backend/DashboardView.vue'
import ProductsView from '@/views/backend/ProductsView.vue'

// ทดสอบสร้างตัวแปรไว้เช็คว่า login หรือยัง
// let state = false

// สร้างฟังก์ชันสำหรับเช็ค route ก่อนเรียกใช้งาน (Route Auth Guard)
function authGuard(to, from, next){
  
  let isAuthenticated = false

  if(localStorage.getItem('user')){
    isAuthenticated = true // ถ้ามีข้อมูล localStorage อยู่
  }else{
    isAuthenticated = false // ถ้าไม่มี localStorage
  }

  if(isAuthenticated){
    next() // อนุญาติให้เข้าสู่ route และโหลด component ที่ต้องการได้
  }else{
    next({name: 'Login'})
  }

}

const routes = [

  /** Frontend Route */
  {
    path: '/',
    component: FrontendLaout,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeView
      }
    ],
    meta: {
      title: 'หน้าหลัก',
      description: 'ระบบคงคลังสินค้า'
    }
  },
  {
    path: '/about',
    component: FrontendLaout,
    children: [
      {
        path: '',
        name: 'About',
        component: AboutView
      }
    ],
    meta: {
      title: 'เกี่ยวกับเรา',
      description: 'เกี่ยวกับเรา'
    }
  },
  {
    path: '/portfolio',
    component: FrontendLaout,
    children: [
      {
        path: '',
        name: 'Portfolio',
        component: PortfolioView
      }
    ],
    meta: {
      title: 'ผลงาน',
      description: 'ผลงาน'
    }
  },
  {
    path: '/contact',
    component: FrontendLaout,
    children: [
      {
        path: '',
        name: 'Contact',
        component: ContactView
      }
    ],
    meta: {
      title: 'ติดต่อเรา',
      description: 'ติดต่อเรา'
    }
  },
  {
    path: '/login',
    component: FrontendLaout,
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginView
      }
    ],
    meta: {
      title: 'เข้าสู่ระบบ',
      description: 'เข้าสู่ระบบ'
    }
  },
  // {
  //   path: '/register',
  //   component: FrontendLaout,
  //   children: [
    //     {
      //   path: '',
      //   name: 'Register',
  //       component: RegisterView
  //     }
  //   ],
    // meta: {
    //   title: 'สมัครสมาชิก',
    //   description: 'สมัครสมาชิก'
    // }
  // },

  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/frontend/RegisterView.vue')
  },


  {
    path: '/service',
    name: 'Service',
    component: FrontendLaout,
    children: [
      {
        path: '',
        component: ServiceView
      }
    ],
    meta: {
      title: 'บริการ',
      description: 'บริการ'
    }
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: FrontendLaout,
    children: [
      {
        path: '',
        component: ForgotPasswordView
      }
    ],
    meta: {
      title: 'ไม่พบหน้าที่เรียก',
      description: 'ไม่พบหน้าที่เรียก'
    }
  },

  // Error 404
  {
    path: "/:catchAll(.*)",
    component: NotFound404View
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/frontend/AboutView.vue')
  // }

  /** Frontend Route */
  {
    path: '/backend',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: DashbaordView,
        beforeEnter: authGuard
        // beforeEnter: (to, from, next) => {
        //   if (state) {
        //     next() // ให้โหลด component ที่เรากำลังเรียกใช้
        //   } else {
        //     next({name: 'Login'})
        //   }
        // }
      }
    ],
    meta:{
      title: 'Dashboard'
    }
  },
  {
    path: '/backend',
    // name: 'Products',
    component: BackendLayout,
    children: [
      {
        path: 'products',
        name: 'Products',
        component: ProductsView,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: 'Products'
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
