<template>
  <section class="bg-indigo-200">
      <div class="container pt-40 pb-20 mx-auto">
          <div class="flex flex-wrap items-center">
              <div class="w-full p-4 lg:w-6/12">
                  <img src="@/assets/img/img-about.png" class="w-full"/>
              </div>
              <div class="w-full p-4 lg:w-5/12 lg:ml-auto">
                  <h6 class="font-medium text-indigo-900">รู้จักเรา</h6>
                  <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">We Work Together To Define, Strategize, Design and Develop</h2>
                  <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae congue tortor.</p>
                  <a href="#" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">เรียนรู้เพิ่มเติม</a>
              </div>
          </div>
      </div>
  </section>
</template>